import axios from 'axios';
import { GET_ACCUMULATED_ORDER_AMOUNT_SETTING } from './types';
import { API_URL } from './../../constants';
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';
import history from './../../utils/history';


export const getAccumulatedOrderAmountSetting = () => dispatch => {
    
    axios.get(API_URL + 'accumulated-order-amount-setting-place')
    .then(res => {
        dispatch({
            type: GET_ACCUMULATED_ORDER_AMOUNT_SETTING,
            payload: res.data
        });
    })
}
