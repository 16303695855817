import { ORDER_EXPIRE_TIME_HOURS, TABLET_ORDER_EXPIRE_TIME_HOURS, KIOSK_ORDER_EXPIRE_TIME_HOURS } from '../constants';
import Cookies from 'universal-cookie';
import { clearCartLocal } from './../products/actions/products.action';
import setCustomPlaceHeader from './../utils/setCustomPlaceHeader';
import jwt from 'jsonwebtoken';
import setAuthorizationToken from './setAuthorizationToken';
import Utils from './Utils';

const cookies = new Cookies();

const TABL_USER_TOKEN_KEY = '_tut_';     // THE TOKEN FOR THE USER LOGIN   (table_user_token)
const TABL_ORDER_TOKEN_KEY = '_tot_';    // THE TOKEN FOR THE ORDER  (tabl_order_token)  // old value by denis - user_token 
const TABL_LANG_KEY = '_tlt_';
const TABL_KIOSK_MODE_KEY = '_tkm_';

class Auth {
	// ------------- tabl_user_token -> USER TOKENA - за логин -----------------------


	static authUser(userToken) {
		let d = new Date();
		let expireHours = 1;

		let decodedUser = jwt.decode(userToken);

		if (decodedUser.exp) {    // sets expiration time like the one from server
			d.setTime(decodedUser.exp * 1000)
		} else {
			d.setTime(d.getTime() + expireHours * 60 * 60 * 1000)
		}

		let config = { path: "/", expires: d };
		if (process.env.NODE_ENV === 'production') {
			config.secure = true; // to force https (if you use it)
			// config.httpOnly = true; // to disable accessing cookie via client side js
			config.sameSite = 'none';	

		} else {
			// config.sameSite = 'strict';			
		}
		cookies.set(TABL_USER_TOKEN_KEY, userToken, config);

		setAuthorizationToken(userToken);

		// window.localStorage.setItem(TABL_USER_TOKEN_KEY, userToken)
	}
	static getDecodedUser() {
		const userJSON = cookies.get(TABL_USER_TOKEN_KEY)
		if (userJSON) {
			return jwt.decode(userJSON)
		}
		return null
	}
	static deauthUser() {
		cookies.remove(TABL_USER_TOKEN_KEY, this.getCookieConfig(0));
		// window.localStorage.removeItem(TABL_USER_TOKEN_KEY)
	}
	static getAuthUserToken() {
		return cookies.get(TABL_USER_TOKEN_KEY);
		// return window.localStorage.getItem(TABL_USER_TOKEN_KEY)
	}



	// -------------- tabl_token -> MENU SESSION ( за поръчката ) ------------------
	static removeUserToken = () => {      // REMOVES ORDER TOKEN NOT USER TOKEN
		// window.localStorage.removeItem(TABL_ORDER_TOKEN_KEY);
		cookies.remove(TABL_ORDER_TOKEN_KEY, this.getCookieConfig(0));
	}

	static ifTokenExpired() {
		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;

			let expires = new Date(user_token.expires);
			const expires_diff = expires.getTime() - new Date().getTime();
			if (expires_diff > 0 /*&& expires_diff < ORDER_EXPIRE_TIME_HOURS * 3600000*/) {
				return false;
			} else {
				return true;
			}
		}

		return true;
	}

	static setUserToken = function (token) {

		let tokenExpireDate = new Date();

		let timeToAdd = ORDER_EXPIRE_TIME_HOURS;
		if (this.getDecodedUser() && this.getDecodedUser().role == "WAITER") {
			timeToAdd = TABLET_ORDER_EXPIRE_TIME_HOURS;
		} else if(this.isKioskMode()) {
			timeToAdd = KIOSK_ORDER_EXPIRE_TIME_HOURS
		}

		tokenExpireDate.setHours(tokenExpireDate.getHours() + timeToAdd);

		if(document.location.href.includes('fernandezNotUsed')) {
			var user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		} else {
			var user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		}

		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;
		} else {
			user_token = {};
		}

		user_token.token = token;
		user_token.expires = tokenExpireDate;

		if(document.location.href.includes('fernandezNotUsed')) {
			window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
		} else {
			cookies.set(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token), this.getCookieConfig(this.isKioskMode() ? KIOSK_ORDER_EXPIRE_TIME_HOURS : 90, 'None'));
		}
	}

	static updateUserTokenExpire = (minutes = 15) => {
		const token = this.getUserToken();
		if (token) {
			const tokenExpireDate = new Date((new Date()).getTime() + minutes * 60000);

			if(document.location.href.includes('fernandezNotUsed')) {
				var user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
			} else {
				var user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
			}
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;
			user_token.expires = tokenExpireDate

			if(document.location.href.includes('fernandezNotUsed')) {
				window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
			} else {
				cookies.set(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token), this.getCookieConfig(this.isKioskMode() ? KIOSK_ORDER_EXPIRE_TIME_HOURS : 90)); //
			}
		}
	}

	static getUserToken = () => {
		if(document.location.href.includes('fernandezNotUsed')) {
			var user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		} else {
			var user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		}

		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;

			let expires = new Date(user_token.expires);
			const expires_diff = expires.getTime() - new Date().getTime();
			if (expires_diff > 0 /*&& expires_diff < ORDER_EXPIRE_TIME_HOURS * 3600000*/) {
				return user_token.token;
			} else {
				return '';
			}
		}
		return '';
	}

	static setTokenOrderType(ordertypeId) {

		if(document.location.href.includes('fernandezNotUsed')) {
			var user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		} else {
			var user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		}

		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;

			user_token.ordertype = ordertypeId

			if(document.location.href.includes('fernandezNotUsed')) {
				window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
			} else {
				cookies.set(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token), this.getCookieConfig(this.isKioskMode() ? KIOSK_ORDER_EXPIRE_TIME_HOURS : 90)); //
			}
		}
	}

	static getTokenOrderType() {
		if(document.location.href.includes('fernandezNotUsed')) {
			var user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		} else {
			var user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		}

		// let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {

			if (this.ifTokenExpired()) {
				this.setTokenOrderType(null);
				return null;
			}
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;

			if (user_token.ordertype) {
				return user_token.ordertype;
			}
		}

		return null;
	}

	static getRoomNumDisabled() {
		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);

		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;
			if (typeof user_token.room_number != "undefined") {
				return user_token.room_number;
			}
		}

		return null;
	}

	static getRoomNumInToken() {
		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;
			if (typeof user_token.room_number != "undefined") {
				return user_token.room_number;
			}
		}

		return null;
	}

	static setRoomNumInToken(roomNum, roomNumberDisabled = false) {

		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;
			user_token.room_number = roomNum
			if (roomNumberDisabled) {
				user_token.room_number_disabled = true;
			}

			cookies.set(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token), this.getCookieConfig(90));
			// window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
		}
	}

	static removeRoomNumInToken() {

		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;
			delete user_token.room_number

			cookies.set(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token), this.getCookieConfig(90));
			// window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
		}
	}

	static setTabletMode() {

		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;
			user_token.tablet_mode = 1

			cookies.set(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token), this.getCookieConfig(this.isKioskMode() ? KIOSK_ORDER_EXPIRE_TIME_HOURS : 90));
			// window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
		}
	}

	static removeTabletMode() {
		
		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;
			user_token.tablet_mode = 0

			cookies.set(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token), this.getCookieConfig(this.isKioskMode() ? KIOSK_ORDER_EXPIRE_TIME_HOURS : 90));
			// window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
		}
	}

	static isTabletMode() {
		if (Utils.getSetting('tablet_mode_always_on') == 1) {
			return true;
		}

		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);

		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;

			if (user_token.tablet_mode) {
				return 1;
			}
		}

		return null;
	}

	static setKioskMode() {
		cookies.set(TABL_KIOSK_MODE_KEY, 1, this.getCookieConfig(KIOSK_ORDER_EXPIRE_TIME_HOURS));
	}

	static removeKioskMode() {
		cookies.remove(TABL_KIOSK_MODE_KEY, this.getCookieConfig(0));
	}

	static isKioskMode() {
		return cookies.get(TABL_KIOSK_MODE_KEY);
	}

	static setOpenedPlace(placeId) {
		// let config = { path: "/" };
		// if (process.env.NODE_ENV === 'production') {
		// 	config.secure = true;
		// }
		// cookies.set('tabl_place', placeId, config);
		// Utils.setJsCookie('tabl_place', placeId, 365);

		if(document.location.href.includes('fernandezNotUsed')) {
			window.localStorage.setItem('tabl_place', placeId);			
		} else {
			cookies.set('tabl_place', placeId, this.getCookieConfig(365 * 24, 'None')); //
		}


		// let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		// if (user_token) {
		// 	user_token = JSON.parse(user_token);
		// 	user_token.place = placeId
		// } else {
		// 	user_token = {};
		// 	user_token.place = placeId;
		// }

		// window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));			
	}

	static getOpenedPlace() {

		if(document.location.href.includes('fernandezNotUsed')) {
			var c = window.localStorage.getItem('tabl_place');
		} else {
			var c = cookies.get('tabl_place');
		}

		if (c) {
			return c;
		}

		return null;

		// let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		// if (user_token) {
		// 	user_token = JSON.parse(user_token);

		// 	if(user_token.place) {
		// 		return user_token.place;
		// 	}
		// }

		// return null;
	}

	static getCookieConfig(expiresHours, sameSite = null) {
		let d = new Date();

		
		d.setTime(d.getTime() + expiresHours * 60 * 60 * 1000)
		let config = { path: "/", expires: d };
		if (process.env.NODE_ENV === 'production') {
			config.secure = true; // to force https (if you use it)
			// config.httpOnly = true; // to disable accessing cookie via client side js
		} else {
			// config.sameSite = 'strict';			
		}

		if(sameSite && process.env.NODE_ENV === 'production') {
			config.sameSite = sameSite;
		}

		return config
	}


	static hasAccess(requiredRole) {
		let currentUser = this.getDecodedUser();

		if (!currentUser) {
			return false;
		}

		let currentRole = currentUser.role;

		switch (requiredRole) {
			case 'USER':
				return false;
			case 'PDF_MENU_ADMIN':
				return (currentRole == 'PDF_MENU_ADMIN' || currentRole == 'WAITER' || currentRole == 'RASPBERRY_ADMIN' || currentRole == 'PLACE_ADMIN' || currentRole == 'CLIENT_ADMIN' || currentRole == 'SALES_ADMIN' || currentRole == 'SUPER_ADMIN')
			case 'WAITER':
				return (currentRole == 'WAITER' || currentRole == 'RASPBERRY_ADMIN' || currentRole == 'PLACE_ADMIN' || currentRole == 'CLIENT_ADMIN' || currentRole == 'SALES_ADMIN' || currentRole == 'SUPER_ADMIN')
			case 'RASPBERRY_ADMIN':
				return (currentRole == 'RASPBERRY_ADMIN' || currentRole == 'PLACE_ADMIN' || currentRole == 'CLIENT_ADMIN' || currentRole == 'SALES_ADMIN' || currentRole == 'SUPER_ADMIN')
			case 'PLACE_ADMIN':
				return (currentRole == 'RASPBERRY_ADMIN' || currentRole == 'PLACE_ADMIN' || currentRole == 'CLIENT_ADMIN' || currentRole == 'SALES_ADMIN' || currentRole == 'SUPER_ADMIN')
			case 'CLIENT_ADMIN':
				return (currentRole == 'RASPBERRY_ADMIN' || currentRole == 'CLIENT_ADMIN' || currentRole == 'SALES_ADMIN' || currentRole == 'SUPER_ADMIN')
			case 'SALES_ADMIN':
				return (currentRole == 'SALES_ADMIN' || currentRole == 'SUPER_ADMIN')
			case 'SUPER_ADMIN':
				return (currentRole == 'SUPER_ADMIN')
			default:
				return false;
		}
	}

	static setWaiterTable(table) {

		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {

			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;

			user_token.table = table

			cookies.set(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token), this.getCookieConfig(90)); //
		}
	}

	static getWaiterTable() {
		let user_token = cookies.get(TABL_ORDER_TOKEN_KEY); //window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = typeof user_token == "string" ? JSON.parse(user_token) : user_token;
			if (typeof user_token.table != "undefined") {
				return user_token.table;
			}
		}

		return null;
	}

	static getCurrLang() {
		let c = cookies.get(TABL_LANG_KEY);
		if (c) {
			return c;
		}

		return null;
	}

	static setCurrLang(lang) {
		cookies.set(TABL_LANG_KEY, lang, this.getCookieConfig(365 * 24, 'None')); //
	}

	static removeCurrLang() {
		cookies.remove(TABL_LANG_KEY, this.getCookieConfig(0));
	}
}

export default Auth
