import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
// import { langReducer } from 'react-redux-multilang'
// import langsReducer from './languages/reducers/langs.reducer';
import categoriesReducer from './categories/reducers/categories.reducer';
import productsReducer from './products/reducers/products.reducer';
import searchReducer from './search/reducers/search.reducer';
import feedbackReducer from './feedback/reducers/feedback.reducer';
import settingsReducer from './settings/reducers/settings.reducer';
import languageReducer from './language/reducers/language.reducer';
import placecesReducer from './places/reducer/places.reducer';
import scanReducer from './qr-scan/reducers/scan.reducer';
import componentReducer from './components/reducers/components.reducer';
import usersReducer from './users/reducers/users.reducer';
import messagesReducer from './messages/messages.reducer';
import clientReducer from './client/client.reducer';
import cartReducer from './cart/reducers/cart.reducer';
import regionsReducer from './regions/regions.reducer';
import userNeedsReducer from './user-needs/reducers/userneeds.reducer';
import generalPromotionsReducer from './general_promotions/reducers/general_promotions.reducer';
import registeredUserDiscounts from './registered_user_discounts/reducers/registered_user_discounts.reducer';
import futureOrdersReducer from './future_orders_count/reducers/future_orders.reducer';
import accumulatedOrderAmountSetting from './accumulated_order_amount_setting/reducers/accumulated_order_amount_setting.reducer';


// import usersReducer from './usersReducer';
// import authReducer from './authReducer';
//Reservations
// import placecesReducer from '../reservations/places/reducers/places.reducer';
// import roomsReducer from '../reservations/rooms/reducers/rooms.reducer';
// import reservationsReducer from './../reservations/reservations/reducers/resevations.reducer';
// import reservationTablesReducer from './../reservations/tables/reducers/tables.reducer';
// import forbiddenReducer from './../reservations/forbidden/reducers/forbidden.reducer';
// import reservationSettingsReducer from './../reservations/settings/constants-settings/reducers/settings.reducer';
// import scheduleReducer from  './../reservations/schedule/reducers/schedule.reducer';
// import reservationSettingsLimitReducer from  './../reservations/settings/people-limit-time/reducers/people-limit.reducer';
// import messagesReducer from './../reservations/messages/messages.reducer';
import { connectRouter } from 'connected-react-router'
import menuReducer from './menu/menu.reducer';
import bookingReducer from './booking/reducers/booking.reducer';
import reservationsReducer from './reservations/reducers/reservations.reducer';
import epayReducer from './payments/epay/epay.reducer';
import promocodeReducer from './promocodes/promocode.reducer';
import paymentsReducer from './payments/payments.reducer';

export default combineReducers({
  // router: connectRouter(history),
  // routing: routerReducer,
  // lang: langReducer,
  // langs: langsReducer,

  categories: categoriesReducer,
  products: productsReducer,
  user_needs: userNeedsReducer,
  search: searchReducer,
  feedback: feedbackReducer,
  settings: settingsReducer,
  lang: languageReducer,
  places: placecesReducer,
  scan: scanReducer,
  // components: componentReducer,
  users: usersReducer,
  messages: messagesReducer,
  client: clientReducer,
  cart: cartReducer,
  regions: regionsReducer,
  menu: menuReducer,
  booking: bookingReducer,
  reservations: reservationsReducer,
  epay: epayReducer,
  promocodeData: promocodeReducer,
  generalPromotions: generalPromotionsReducer,
  registeredUserDiscount: registeredUserDiscounts,
  futureOrders:futureOrdersReducer,
  payments: paymentsReducer,
  accumulatedOrderAmountSetting: accumulatedOrderAmountSetting
  //   users: usersReducer,
  //   auth: authReducer,
})

