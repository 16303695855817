import React, { useEffect, lazy, Suspense } from 'react';
import { connect } from "react-redux";
import { Switch, Route, Router } from 'react-router-dom';
import history from './utils/history';
// import AppNavbar from './common/navbar/AppNavbar'
import CircularProgress from '@material-ui/core/CircularProgress';

// import ClientRequest from './landing-pages/vkluchi-se/components/ClientRequest';
// import Thankyou from './landing-pages/vkluchi-se/components/Thankyou';

// import SignInPage from './components/auth/signInPage';
// import ScannerPage from './components/qr_scanner/scannerPage';
// import LogoutPage from './components/auth/logoutPage';
// import Page404 from './common/errors/404';
// import PreorderPage from './components/preorder/PreorderPage';
// import FeedbackPage from './feedback/components/FeedbackPage';
// import SearchPage from './search/components/SearchPage';
// import AboutUsPage from './aboutus/components/AboutUsPage';
// import AboutRestaurantPage from './aboutus/components/AboutRestaurantPage';
// import AllProductsPage from './products/components/AllProductsPage';
// import SubcatsWithProductsPage from './products/components/SubcatsWithProductsPage';
// import SubcatsWithProductsSluggedPage from './products/components/SubcatsWithProductsSluggedPage';
// import NavigateToComponent from './common/NavigateToComponent';
// import ScanPage from './qr-scan/components/scanPage';
// import VerifyTokenPage from './qr-scan/components/VerifyTokenPage';
import CategoriesHierarchyPage from './categories/components/CategoriesHierarchyPage';
// import CategoriesHierarchyPageAllProducts from './categories/components/CategoriesHierarchyPageAllProducts';
import ViewProductPage from './products/components/ViewProductPage';
// import ProfilePage from './users/components/profile/ProfilePage';
import DynamicLayoutRoute from './DynamicLayoutRoute';

// const AllCatsAndProdsPage = lazy(() => import('./categories/components/AllCatsAndProdsPage'));

// import AllCatsAndProdsPage from './categories/components/AllCatsAndProdsPage';
const AllCatsAndProdsPage = lazy(() => import('./categories/components/AllCatsAndProdsPage'));
const FeedbackPage = lazy(() => import('./feedback/components/FeedbackPage'));
const AboutUsPage = lazy(() => import('./aboutus/components/AboutUsPage'));
const ProfilePage = lazy(() => import('./users/components/profile/ProfilePage'));
const OrdersPage = lazy(() => import('./users/components/profile/ordersPage/OrdersPage'));
// const ViewProductPage = lazy(() => import('./products/components/ViewProductPage'));
const SearchPage = lazy(() => import('./search/components/SearchPage'));
const AboutRestaurantPage = lazy(() => import('./aboutus/components/AboutRestaurantPage'));
const VerifyTokenPage = lazy(() => import('./qr-scan/components/VerifyTokenPage'));
const Page404 = lazy(() => import('./common/errors/404'));
// const ScanPage = lazy(() => import('./qr-scan/components/scanPage'));

const ClientRequest = lazy(() => import('./landing-pages/vkluchi-se/components/ClientRequest'));
const Thankyou = lazy(() => import('./landing-pages/vkluchi-se/components/Thankyou'));
const HomePage = lazy(() => import('./landing-pages/home_page/components/HomePage'));
const PlaceGalleryPage = lazy(() => import('./landing-pages/place_gallery_page/components/PlaceGalleryPage'));

const LunchMenuPage = lazy(() => import('./landing-pages/lunch-menu/components/LunchMenuPage'));
const LunchMenuSubscribePage = lazy(() => import('./landing-pages/lunch-menu/components/SubscribePage'));

const ReservationPage = lazy(() => import('./reservations/components/ReservationsPage'));
const PaymentProcessingPage = lazy(() => import('./payments/PaymentProcessingPage'));
const CustomTermsPage = lazy(() => import('./landing-pages/custom_terms_page/components/CustomTermsPage'));

// const renderLoader = () => <p style={{zIndex: 10000, position: 'аbsolute', top: 100, left:100}}>Loading</p>;
const renderLoader = () => <CircularProgress className="is-loading" />;

class MyRouter extends React.Component {

    render() {
        const { settings } = this.props;

        return (
            <div>
                <Suspense fallback={renderLoader()}>
                    <Router history={history}>
                        <div>
                            {/* <AppNavbar hist ory={history}/> */}
                            <Switch>
                                <DynamicLayoutRoute exact path="/404" component={Page404} layout="DEFAULT_NAV" />
                                <DynamicLayoutRoute exact path="/join" component={ClientRequest} layout="DEFAULT_NAV" />
                                <DynamicLayoutRoute exact path="/thankyou" component={Thankyou} layout="DEFAULT_NAV" />
                                <DynamicLayoutRoute exact path="/close-browser-app" component={Thankyou} layout="DEFAULT_NAV" />
                                {/* <DynamicLayoutRoute exact path="/lunch-menu" component={LunchMenuPage} layout="DEFAULT_NAV" /> */}
                                {/* <DynamicLayoutRoute exact path="/lunch-menu/subscribe" component={LunchMenuSubscribePage} layout="DEFAULT_NAV" /> */}

                                <DynamicLayoutRoute exact path="/:place/reservations" component={ReservationPage} layout="MENU_NAV" />
                                {/* <DynamicLayoutRoute exact path="/:place/feedback" component={FeedbackPage} layout="MENU_NAV" /> */}
                                <DynamicLayoutRoute exact path='/:place/payment' component={PaymentProcessingPage} layout="MENU_NAV" />

                                <DynamicLayoutRoute exact path='/:place/feedback' component={FeedbackPage} layout="MENU_NAV" />
                                <DynamicLayoutRoute exact path='/:place/search' component={SearchPage} layout="MENU_NAV" />
                                <DynamicLayoutRoute exact path='/:place/all' component={AllCatsAndProdsPage} layout="MENU_NAV" />
                                <DynamicLayoutRoute exact path='/:place/about-tabl' component={AboutUsPage} layout="MENU_NAV" />
                                <DynamicLayoutRoute exact path='/:place/about' component={AboutRestaurantPage} layout="MENU_NAV" />
                                <DynamicLayoutRoute exact path='/:place/profile' component={ProfilePage} layout="MENU_NAV" />
                                <DynamicLayoutRoute exact path='/:place/profile/orders' component={OrdersPage} layout="MENU_NAV" />

                                <DynamicLayoutRoute exact path='/:place/gallery' component={PlaceGalleryPage} layout="MENU_NAV" />
                                <DynamicLayoutRoute exact path='/:place/custom-terms' component={CustomTermsPage} layout="MENU_NAV" />

                                {/* <Route exact path='/:place/subcategories/:subcat_id/products' component={NavigateToComponent} /> */}
                                {/* <DynamicLayoutRoute exact path='/:place/scan' component={ScanPage} layout="MENU_NAV" /> */}
                                <DynamicLayoutRoute exact path='/:place/t/:token' component={VerifyTokenPage} layout="MENU_NAV" />
                                <DynamicLayoutRoute exact path='/:place/:catSlug/:prodSlug' component={ViewProductPage} layout="MENU_NAV" />

                                <DynamicLayoutRoute exact path='/:place/:catSlug' component={settings.one_page_menu_style && settings.one_page_menu_style.value == '1' ? AllCatsAndProdsPage : CategoriesHierarchyPage} layout="MENU_NAV" />
                                <DynamicLayoutRoute exact path='/:place' component={settings.one_page_menu_style && settings.one_page_menu_style.value == '1' ? AllCatsAndProdsPage : CategoriesHierarchyPage} layout="MENU_NAV" />

                                <DynamicLayoutRoute exact path='/' component={HomePage} layout="MENU_NAV" />
                            </Switch>
                        </div>

                    </Router>
                </Suspense>

            </div>
        )
    }
}

const mapStateToProops = (state, ownProps) => {
    return {
        settings: state.settings.settings || [],
    };
};

export default (connect(mapStateToProops, {})(MyRouter));