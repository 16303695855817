import { GET_ACCUMULATED_ORDER_AMOUNT_SETTING, GET_BUSY_DATES } from './../actions/types';

const initialState = {
    setting: [],
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_ACCUMULATED_ORDER_AMOUNT_SETTING:
            return {
                ...state,
                setting: action.payload,
            } 
       
        default:
            return state;
    }
}